<template>
  <b-card>
    <div class="mb-2 d-flex justify-content-end">
      <!-- Table Top -->
      <b-button variant="success" @click="updatePrices()">
        {{ $t("update") }}
      </b-button>
    </div>
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row v-for="item in prices" :id="item.id" :key="item.id" ref="row">
          <b-col md="2" sm="5" cols="6" class="d-flex align-items-center">
            <h4>
              <span class="font-weight-bold"
                >({{ item.kod }}) {{ item.ingredientname }}
              </span>
            </h4>
          </b-col>
          <!-- Price -->
          <b-col md="2" sm="3" cols="6">
            <b-form-group :label="item.vade0name" :label-for="item.vade0name">
              <b-form-input
                :id="item.vade0name"
                v-model="item.price"
                class="text-right"
                type="number"
                :options="{
                  numeral: true,
                }"
                @input="changedItem(item)"
              />
            </b-form-group>
          </b-col>
          <!-- vade1 -->
          <b-col md="2" sm="3" cols="6">
            <b-form-group :label="item.vade1name" :label-for="item.vade1name">
              <b-form-input
                :id="item.vade1name"
                v-model="item.vade1"
                class="text-right"
                :value="item.vade1"
                type="number"
                @input="changedItem(item)"
              />
            </b-form-group>
          </b-col>
          <!-- vade2 -->
          <b-col md="2" sm="3" cols="6">
            <b-form-group :label="item.vade2name" :label-for="item.vade2name">
              <b-form-input
                :id="item.vade2name"
                v-model="item.vade2"
                class="text-right"
                :value="item.vade2"
                type="number"
                @input="changedItem(item)"
              />
            </b-form-group>
          </b-col>
          <!-- vade3 -->
          <b-col md="2" sm="3" cols="6">
            <b-form-group :label="item.vade3name" :label-for="item.vade3name">
              <b-form-input
                :id="item.vade3name"
                v-model="item.vade3"
                class="text-right"
                :value="item.vade3"
                type="number"
                @input="changedItem(item)"
              />
            </b-form-group>
          </b-col>
          <!-- vade4 -->
          <b-col md="2" sm="3" cols="6">
            <b-form-group :label="item.vade4name" :label-for="item.vade4name">
              <b-form-input
                :id="item.vade4name"
                v-model="item.vade4"
                class="text-right"
                :value="item.vade4"
                type="number"
                @input="changedItem(item)"
              />
            </b-form-group>
          </b-col>
          <!-- vade4 -->

          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      prices: [],
      changedItems: [],
    };
  },
  async mounted() {
    const { data } = await this.$http.get("/Ingredients/GetProductPrices/");
    this.prices = data.resultdata;

    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    changedItem(item) {
      var index = this.changedItems.map((x) => x.id).indexOf(item.id);
      if (index == -1) this.changedItems.push(item);
      else this.changedItems.splice(index, 1, item);
    },
    async updatePrices() {
      var besli = this.changedItems.map((x) => {
        return {
          id: x.id,
          price: parseFloat(x.price),
          vade1: parseFloat(x.vade1),
          vade2: parseFloat(x.vade2),
          vade3: parseFloat(x.vade3),
          vade4: parseFloat(x.vade4),
        };
      });
      var result = await this.$http.post(
        "/Ingredients/UpdateProductPrices/",
        besli
      );
      if (result.data.status == "OK")
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("prices") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("prices") }),
          },
        });
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
